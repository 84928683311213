import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, Stack, Typography, useTheme, styled, css, Box } from '@mui/material'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import StockMessage from '../../../atoms/StockMessage/StockMessage'

const BUTTON_ID = 'header-shop-deliver-menu-btn'
const MENU_ID = 'header-shop-deliver-menu'

const StyledTypography = styled(Typography, {
  shouldForwardProp: prop => !['darker', 'inverted'].includes(prop),
})(
  ({ theme, inverted, darker }) => css`
    font-size: 0.875rem;
    color: ${theme.palette.primary.main};
    display: inline;

    .stock-message > span {
      color: ${theme.palette.success.main};
    }

    ${inverted &&
      css`
        color: ${theme.palette.success.main};
      `}
    ${darker &&
      css`
        color: ${theme.palette.primary.dark};
      `}
  `,
)

const StyledLocalShippingOutlinedIcon = styled(LocalShippingOutlinedIcon)(
  ({ theme }) => css`
    color: ${theme.palette.primary.dark};
  `,
)

const StyledParagraph = styled('p')`
  font-size: 14px;

  &.mt-2 {
    margin-top: 8px;
  }

  strong {
    color: #003566;
  }
`

const StyledStockMessageContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  '& > *:not(:first-child)': {
    marginLeft: '5px',
  },
}))

const LTLmessageContent = () => (
  <Typography variant="subtitle">
    The carrier will contact you directly to schedule your delivery, which includes placing the box into the first room
    of your home. <strong>Please note that freight deliveries can take 7-21 days in transit.</strong>
  </Typography>
)

const DeliveryInfo = ({
  shippingAddress,
  stockMessage,
  children,
  className,
  displayLTLmessage,
  assemblyRequiredNote,
}) => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const theme = useTheme()

  const onClickOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const buttonAriaProps = {
    'aria-haspopup': 'true',
    'aria-label': 'Change Shop and Deliver to location',
    ...(open
      ? {
          'aria-controls': MENU_ID,
          'aria-expanded': 'true',
        }
      : {}),
  }

  return (
    <Box className={className}>
      <Stack direction="column">
        <Stack direction="row" alignItems="center" gap={1}>
          <StyledLocalShippingOutlinedIcon fontSize="1.5rem" />
          <Typography color={theme.palette.primary.dark}>Delivery to:</Typography>
          <Button
            sx={{
              marginLeft: -1,
            }}
            variant="text"
            size="medium"
            id={BUTTON_ID}
            ref={anchorRef}
            data-testid="changeShopDeliverToLocation"
            onClick={onClickOpen}
            {...buttonAriaProps}
          >
            <Typography
              color="primary"
              fontWeight={600}
              sx={{
                textTransform: 'capitalize',
                textDecoration: 'underline',
              }}
            >
              {shippingAddress}
            </Typography>
          </Button>
        </Stack>
        <StyledTypography darker component="div">
          <StyledStockMessageContainer>
            <StockMessage stockMessage={stockMessage} />
          </StyledStockMessageContainer>
          {displayLTLmessage && (
            <StyledParagraph className="mt-2">
              <LTLmessageContent />
            </StyledParagraph>
          )}
          {assemblyRequiredNote && (
            <StyledParagraph className="mt-2">
              <strong>{assemblyRequiredNote}</strong>
            </StyledParagraph>
          )}
        </StyledTypography>
      </Stack>
      <Menu
        open={open}
        onClose={onClose}
        anchorEl={anchorRef.current}
        MenuListProps={{
          'aria-labelledby': BUTTON_ID,
          as: 'div',
        }}
      >
        {children}
      </Menu>
    </Box>
  )
}

DeliveryInfo.propTypes = {
  shippingAddress: PropTypes.string.isRequired,
  stockMessage: PropTypes.any.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  displayLTLmessage: PropTypes.bool,
  assemblyRequiredNote: PropTypes.string,
}

export default DeliveryInfo
